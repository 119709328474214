<template>

<com-content :blue="true" class="features">

	<com-intro />

	<com-feature title="Registration" poster="discover-a.jpg" video="discover-a.mp4" :tags="['Sales', 'Badges', 'Scanning', 'Checkins', 'Merchandise', 'Add-ons']" />
	<com-feature title="Schedule" poster="discover-c.jpg" video="discover-c.mp4" :tags="['Events', 'Lobbies', 'Tickets', 'Contests', 'Rooms', 'Messages', 'Buddies', 'Proposals', 'Filtering']" />
	<com-feature title="Checkout" poster="discover-b.jpg" video="discover-b.mp4" :tags="['Game Scanning', 'Player Scanning', 'Loan History', 'Play to Win', 'Component Reports']" />

</com-content>
	
</template>

<script>

export default {

	components: {

		'com-content': () => import('./common/Content'),
		'com-intro': () => import('./features/Intro'),
		'com-feature': () => import('./features/Feature'),

	}

}

</script>

<style scoped>
</style>